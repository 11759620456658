<!--
 Name: Background.vue
 Description: View to display the scientific background page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2024-10-08
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-12">
      <h2 class="my-12">{{ $t("background.headline") }}</h2>
      <p class="text-justify">{{ $t("background.text") }}</p>

      <!-- anatomically related tests -->
      <v-row class="my-12">
        <v-col cols="12">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-3">{{ $t("background.tests-title") }}</h3>
          <p>{{ $t("background.tests-text") }}</p>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.display.mdAndUp">
        <v-img
          :src="anatomicallyRelated"
          height="650"
        />
      </v-row>
      <v-row v-if="$vuetify.display.smAndDown" justify="center">
        <v-col cols="12">
          <v-img
            :src="brainRegions_en"
            height="350"
            class="mb-12"
          />
          <h2 class="mt-12 bg-primary--text">
            {{ $t("background.tests-neotiv-title") }}
          </h2>
          <br />
          <h3 class="mb-3 text-primary">Mnemonic Discrimination Task for Objects and Scenes (MDT-OS)</h3>
          <v-img
            :src="mdt_os"
            height="350"
            class="my-12"
          />
          <h3 class="mb-3 text-primary">Object-In-Room Recall (ORR)</h3>
          <v-img
            :src="orr"
            height="350"
            class="my-12"
          />
          <h3 class="mb-3 text-primary">Complex Scene Recognition (CSR)</h3>
          <v-img
            :src="csr"
            height="350"
            class="my-12"
          />
        </v-col>
      </v-row>

      <!-- functional networks -->
      <v-row class="mb-12 mt-15">
        <v-col cols="12">
          <div class="square-left bg-primary mb-8 mt-12"></div>
          <h3 class="mb-3">{{ $t("background.functional-networks-title") }}</h3>
          <p>{{ $t("background.functional-networks-text") }}</p>
        </v-col>
      </v-row>
      <v-img
        :src="functionalNetworks"
        height="300"
      />

      <!-- zoom -->
      <v-row class="mb-9 mt-15">
        <v-col cols="12">
          <div class="square-left bg-primary mb-8 mt-12"></div>
          <h3 class="mb-3">{{ $t("background.zoom-title") }}</h3>
          <p>{{ $t("background.zoom-text") }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-9">
        <v-col cols="12" md="6">
          <v-img
            :src="zoomOut_en"
            height="300"
            class="mt-6"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :src="zoomIn_en"
            height="400"
            class="mt-6"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- science behind neotiv -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("studies.science-behind-neotiv") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5 px-12"
            :to="'/' + $i18n.locale + '/studies'"
            >{{ $t("footer.science.studies") }}</v-btn
          >
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5 px-12"
            :to="'/' + $i18n.locale + '/papers'"
            >{{ $t("studies.papers") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  computed: {
    anatomicallyRelated() {
      return require("@/assets/images/science/anatomically_related_en.svg");
    },
    brainRegions_en() {
      return require("@/assets/images/science/brain_regions_en.svg");
    },
    csr() {
      return require("@/assets/images/science/test_csr.svg");
    },
    mdt_os() {
      return require("@/assets/images/science/test_mdt_os.svg");
    },
    orr() {
      return require("@/assets/images/science/test_orr.svg");
    },
    functionalNetworks() {
      return require("@/assets/images/science/functional_networks_en.svg");
    },
    tmt_en() {
      return require("@/assets/images/science/tmt_en.svg");
    },
    zoomIn_en() {
      return require("@/assets/images/science/zoomIn_en.svg");
    },
    zoomOut_en() {
      return require("@/assets/images/science/zoomOut_en.svg");
    }
  }
};
</script>
