<!--
 Name: Repeated Learning .vue
 Description: View to display the (hidden) Repeated Learning Stimuli Validation page.
 Created by: Lokeswari Madhusudhana on 2024-06-18
 Last edited by: Lokeswari Madhusudhana on 2024-08-27
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- hero graphic -->
    <hero-component
      title="Welcome to the 'Repeat' study"
      text="Here you will find step-by-step instructions and information about the study procedure and how to download and log in to the neotivTrials app."
      :image="require('@/assets/images/home/header-brain.svg')"
      :ratio="1"
      :heroColor="'#57509C'"
      :moreBtnHidden="true"
    >
    </hero-component>

    <!-- course of the 'Repeat' study -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Course of the 'Repeat' study
          <v-divider></v-divider>
        </h2>
        <p>
          Over a course of 5 consecutive days, there will be one memory test each day.
          Additionally, questionnaires are included in the first and last test session.
        </p>
        <!-- study design -->
        <v-img
          :src="repeatedLearningStudyDesign"
          max-width="100%"
        />
        <br>
        <p><b>Study content</b></p>
        <ul class="ml-12 my-2">
          <li>The memory test will take about <b>20 minutes.</b> You will do <b>one test every day</b>, each consisting of two test parts, over a total time span of <b>5 days</b>.</li>
          <li>The additionally included questionnaires will each take about <b>3 to 10 minutes.</b> Questionnaires will be done at the beginning of the study and at the very end.</li>
        </ul>
        <p>Further explanations for questionnaire content as well as how to perform the tests will be provided within the neotivTrials app.</p>
        <br>
        <p><b>Important:</b>
          Please note that the tests do expire if they are not performed during the day. Hence, in order to be paid the entire amount, you will need to perform one test each day for 5 days.</p>
        <br>
        <p class="mb-2"><b>Payment</b></p>
        <p>You will get paid <b>separately for the first test </b>and for the <b>remaining 4 tests</b> at the end of the study</p>
        <ul class="ml-12 my-2">
          <li><b>After the first test</b>, you will receive a <b>unique completion code</b> in the app.</li>
          <li>This completion code can then be <b>entered on the Prolific page</b> to receive <b>the first</b> payment.</li>
          <li>You will get invited to a separate Prolific study on Prolific after the first test has been performed.</li>
          <li><b>After the last test</b>, you will receive a <b>further completion code</b> from the neotivTrials app </li>
          <li>This completion code can then be <b>entered on the Prolific page</b> to receive <b>the second</b> payment.</li>
          <li>We pay <b>9£ / ~$11.45 per hour</b></li>
        </ul>
        <p>For the <b>entire study</b> participation, you can earn <b>18£ / ~$22,85</b>!</p>
        <br>
        <p class="mb-2"><b>Exclusion criteria</b></p>
        <p>
          Please note that persons with one or more of the following conditions are not eligible for participation:
        </p>
        <ul class="ml-12 my-2">
          <li>Prior experience with the neotivTrials app – we seek participants who have not yet performed any of the neotiv tests so far</li>
          <li>Existing diagnosis with mild cognitive impairment or dementia</li>
          <li>Current or previous diagnosis with a mental health/illness/condition e.g. depression or anxiety</li>
          <li>Current or previous injury to the head that's caused you to be unconscious for a period of time</li>
        </ul>
      </v-container>
    </section>
    <!-- Start your participation -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Start your participation
          <v-divider></v-divider>
        </h2>
        <!-- How does it work? -->
        <h3 class="text-left my-6">How does it work?</h3>
        <p>In general, the study participation works as follows:</p>
        <br>
        <p class="mb-2"><b>Phase 1:</b><br><b>Download</b> the neotivTrials app and log into the study. We provide you with <b>step-by-step instructions</b> in the section below.</p>
        <p class="mb-2"><b>Phase 2:</b><br><b>Follow the schedule and complete one test per day.</b>
          The neotivTrials app guides you step by step through the study and explains the memory test and questionnaire. After the first and the last test, you will each receive unique completion codes. Submit the completion code on the respective Prolific invitation and get paid!
        </p>
        <p><b>Phase 3:</b><br><b>Participate</b> until the end of the study! </p>
      </v-container>
    </section>

    <v-container>
      <!-- Step-by-step instructions -->
      <h3 class="text-left my-6">Step-by-step instructions</h3>
      <v-expansion-panels>
        <!-- Get your access code and password -->
        <v-expansion-panel
          title="1. Get your access code and password for the neotivTrials app"
        >
          <v-expansion-panel-text>
            <!-- video instruction -->
            <v-row no-gutters>
              <video
                ref="video"
                controls
                controlsList="nodownload"
                oncontextmenu="return false;"
                width="454"
              >
                <source :src="prolificVideoInstruction" type="video/mp4">
                Sorry, your browser doesn't support embedded videos.
              </video>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-6"><b>Step 1:</b> Visit the <b><a href="https://api.neotiv.com/External/ExternalRegistration?token=prolificreplearning">link</a></b> to receive your access code and password.</p>
                <p class="mb-6"><b>Step 2:</b> Enter your Prolific email address.</p>
                <p class="mb-6"><b>Step 3:</b> Click the "SUBMIT" button. Then you will receive your access code and password instructions in your Prolific Messages.</p>
                <v-img
                  :src="registerWithProlificEmail"
                  max-width="454"
                  cover
                />
                <p class="my-6"><b>Step 4:</b> Check your messages on your Prolific account and find a message from neotiv.</p>
                <p class="mb-6"><b>Step 5:</b> In the neotiv message, you will find an access code and a long URL link.</p>
                <v-img
                  :src="prolificAccessCodeAndLink"
                  max-width="454"
                  cover
                />
                <p class="my-6"><b>Step 6:</b> Copy the link and paste it into your browser.</p>
                <p class="mb-6"><b>Step 7:</b> The link will take you to a neotiv website where you can set your password.</p>
                <v-img
                  :src="setPassword"
                  max-width="454"
                  cover
                />
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Installing neotivTrials app -->
        <v-expansion-panel
          title="2. Install the neotivTrials app"
        >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-6"><b>Step 1: Check technical requirements</b><br>You can use the neotivTrials app on a tablet or smartphone that uses at least <b>iOS version 12</b> or <b>Android version 9</b>. The version number is in the settings of your mobile device.</p>
                <p class="mb-6"><b>Step 2: Check your internet access</b><br>Your mobile device needs a stable internet connection via WIFI or mobile data.</p>
                <p class="mb-6"><b>Step 3: Install the neotivTrials app</b><br>The neotivTrials app can be downloaded in your app store. Please follow the link for the <a href="https://apps.apple.com/app/neotivtrials/id1611173081" target="_blank">App Store (iOS)</a> or the <a href="https://play.google.com/store/apps/details?id=com.neotiv.app.trials" target="_blank">Google Play (Android)</a>. You can also scan the QR code below to get to your respective app store.</p>
              </v-col>
            </v-row>
            <v-row class="mt-0" no-gutters>
              <v-col cols="4" class="py-6">
                <v-img
                  class="ml-2"
                  :src="appleLogo"
                  max-width="180"
                  cover
                />
                <v-img
                  :src="qrTrialsApple"
                  max-width="200"
                  cover
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="8" class="py-6">
                <v-img
                  class="ml-2"
                  :src="androidLogo"
                  max-width="180"
                  cover
                />
                <v-img
                  :src="qrTrialsGoogle"
                  max-width="200"
                  cover
                />
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Accessing the study -->
        <v-expansion-panel
          title="3. Enroll for the study in the neotivTrials app"
        >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-6"><b>Step 1:</b><br>Click through the initial information in the app until you get to the <b>"What do you want to use the app for?" screen.</b></p>
                <p class="mb-6"><b>Step 2:</b><br>Choose item "Studies (restricted access)".</p>
                <v-img
                  :src="studiesRestrictedAcess"
                  max-width="200"
                  cover
                />
                <p class="my-6"><b>Step 3:</b><br>Use your access code and password from the <b>message by neotiv in your Prolific account</b> to log into the study.</p>
                <v-img
                  :src="login"
                  max-width="200"
                  cover
                />
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Data privacy -->
        <v-expansion-panel
          title="4. Data privacy"
        >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-6">Your information will remain safe as we operate under the <b>EU General Data Protection Regulation (GDPR)</b>. The data will be processed by neotiv as well as the collaborating research institute in Magdeburg, Germany (IKND - Institute of Cognitive Neurology and Dementia Research).</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Support Information -->
        <v-expansion-panel>
          <v-expansion-panel-title disable-icon-rotate>
            <span>5. Information: Time restrictions for customer support</span>
            <template v-slot:actions>
              <v-icon color="error" icon="mdi-alert-circle">
              </v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12">
                <br>
                Customer support will be available Monday to Friday during the <b>German times between 8 am and 5 pm.</b> We assist you as soon as we can!
                <br>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <!-- Submissions on Prolific -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Completing neotiv test and the submission on Prolific
          <v-divider></v-divider>
        </h2>
        <br>
        <v-expansion-panels>
          <v-expansion-panel
            title="Day 1 / Test 1"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">On the first day, follow the registration and installation process explained above to download and access the neotivTrials app. Complete the first questionnaire and memory test in the app. Then, enter the provided completion code from the app on the Prolific study invitation.</p>
                  <p class="mb-3">Note: If you time out in the Prolific study, it's not a problem. We will still register your contribution and pay for your valid data.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Day 2 / Test 2 until Test 5"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">You will be invited to one further study on the Prolific website. Via this Prolific study, we can compensate for completing test 2-5.</p>
                  <b>Please start the respective study on Prolific AFTER you have performed the very last test in the neotivTrials app (Test 5).</b>
                  <p class="mb-3">Enter the new completion code provided in the app directly after you finish this memory test in the app.</p>
                  <b>If the last test 5 is not yet available in the app, please wait until it becomes available and do not start the respective Prolific study yet.</b>
                  <p class="mb-3">As soon as you have time to start and complete the entire test (part 1 and 2), afterward start the Prolific study. Enter the completion code on Prolific immediately.</p>
                  <b>Your study spot on Prolific is reserved and cannot be taken by anyone else meaning the study cannot run out.</b>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </section>

    <!-- The fine print -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          The fine print
          <v-divider></v-divider>
        </h2>
        <p>This study processes health-related and demographic data as well as test data.  Before starting the study, <b>you will be asked within the neotivTrials app to provide your informed consent.</b> Therefore, we would like to inform you about the details of the study aim and data processing. <b>Please read the information in the drop-down menu below.</b></p>
        <br>
        <v-expansion-panels>
          <v-expansion-panel
            title="Benefits and risks"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <b>Aim of the “Repeat” study</b>
                  <p class="mb-3">The aim of the study is to assess the ability to memorize images that are shown repeatedly over a period of one week. In addition, the usability of the app-based test is assessed in one questionnaire.</p>
                  <b>Who can participate in the study?</b>
                  <p class="mb-3">Registered Prolific users between 45 and 85 years of age can participate in the study. We are looking for participants who do not have any previous neurological diseases.</p>
                  <b>Benefits and risks</b>
                  <p class="mb-3">The studies are not associated with any risks.
                    By cooperating, you would make an important contribution to answering our study questions. You will not gain any direct personal benefit from participating in this study.
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Your rights"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <b>Your rights</b>
                  <p>You can withdraw from participation at any time without giving any reason and without any disadvantages. To do so, please send a message via the <a @click="scrollToContact">‘Contact for Support’</a> on the bottom of this Website. You will of course be compensated for each study contributions made. You have the right to refuse to answer any questions. All your questions about the study will be answered as long as this does not affect the results of the study. If there are remaining questions, please send a message via the <a @click="scrollToContact">‘Contact for Support’</a> on the bottom of this Website.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Data handling"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <b>What happens to my data?</b>
                  <p class="mb-3">As part of the study, personal information will be collected digitally. The data will be stored and analyzed anonymously. The data is secured against unauthorized access and is stored after the end of the study.
                    Data will be processed by neotiv only.
                  </p>
                  <b>Dealing with test results</b>
                  <p>You cannot expect immediate test results for yourself. No medical diagnoses are made. The results can be published in a scientific journal and are used to optimize the neotiv memory tests. </p>
                  <b>Expense allowance</b>
                  <p>Your participation in this study is voluntary. There will be financial compensation of 9£ per hour.
                    Consent will be asked to be given within the neotivTrials app.
                    Refusing these terms as well as executing the right to revoke the consent will result in inability to participate in the “Repeat” study of neotiv through Prolific.
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-container>
    </section>

    <!-- Frequently Asked Questions -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left mb-6">
          Frequently Asked Questions (FAQs)
          <v-divider></v-divider>
        </h2>
        <v-expansion-panels>
          <v-expansion-panel
            title="What if I am unable to submit the registration meaning the 'Submit' button remains grey?"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">The Prolific email address (YourProlificID@email.prolific.com) is not entered properly in the respective field. This can be done by using the copy & paste function.</p>
                  <p class="mb-3">There might be a <b>blank space</b> within the entry of your Prolific email address, which needs to be deleted. It can also help to just <b>delete the last letter of the email address and rewrite it</b>.</p>
                  <p class="mb-3">In case this does not work, please send a message via the "Contact for Support" on the bottom of this Website.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="What if I do not receive a message with an access code and a link to set the password?"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">If you have successfully finished the submission on the registration page, you should receive a message in your Prolific account. Please check your Prolific messages where you should find a text with all further information.</p>
                  <p class="mb-3">In case you do not receive this information, please send a message via the "Contact for Support" on the bottom of this Website.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="What if I am unable to log into the neotivTrials app using the access code and password? The error message 'Sorry, login is not possible' appears in the app."
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">The access code needs to be entered in small letters.</p>
                  <p class="mb-3">In some cases, a blank space after the access code hinders a successful login. Also, the self-set password could be entered incorrectly.</p>
                  <p class="mb-3">You can try to close and restart the app - This error message occurs with incorrect credentials so maybe there might still be a typo in either of the fields.</p>
                  <p class="mb-3">In case this does not work, please send a message via the "Contact for Support" on the bottom of this Website.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="How does the data submission and payment work?"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">Once you have started the study within the neotivTrials app, the app will guide you through the entire study over the 5 days and remind you via push-notifications on your device about the next upcoming test.</p>
                  <p class="mb-3">The first payment will be made after the first memory test. To receive the payment, please enter the completion code provided in the neotivTrials app into the Prolific invitation.</p>
                  <p class="mb-3">Test 2 to 5 will be paid collectively in a separate Prolific invitation. To submit this study, please enter the second completion code that is provided in the neotivTrials app after the very last test. This guarantees that you get paid for each contribution you make to the study.</p>
                  <p class="mb-3">After you finished the first test, a few days later, you will be invited to the second Prolific study to make sure you can start the test in the app once it is available and enter the completion code in the respective Prolific study.
                    Afterward, we check the generated data for general validity until you receive the payment.</p>
                  <p class="mb-3"> In case of questions toward the study, please send a message via the "Contact for Support" on the bottom of this Website.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="When is my study contribution going to be paid?"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">The submission will be paid if the data fulfills the basic data validity criteria after our data submission check. We have up to 21 days to perform the data submission.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="I was invited on Prolific to the next study unit(s) but the neotivTrials app does not show my next test to be available"
          >
            <v-expansion-panel-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-6">
                  <p class="mb-3">Once the neotivTrials app makes the next test available and you have the time to start (and complete) the test unit, then right after performing the whole test (part 1 and 2) in the app, start the Prolific study for the respective test unit and enter the completion code on Prolific right away. Your spot is reserved for your ID specifically and cannot run out.</p>
                  <p class="mb-3"> You can leave the invitation(s) on Prolific open until you are ready to perform the test in the app and enter the completion code afterward.</p>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </section>


    <!-- Contact form -->
    <section class="contact bg-primary">
      <v-container class="py-12">
        <v-container>
          <h3 class="text-white text-center">
            Contact for Support
          </h3>
          <p class="text-white mt-7">
            In case you have troubles downloading the app or accessing the study, you can contact us by using the contact form.
          </p>
        </v-container>
        <contact-form
          :formColor="'#57509C'"
          :formBtnColor="'#6DC1B9'"
          :newsletter="false"
        />
      </v-container>
    </section>

  </v-container>
</template>

<script>
import ContactForm from "@/components/Form";
import HeroComponent from "@/components/Hero";

export default {
  components: {
    ContactForm,
    HeroComponent
  },
  methods: {
    scrollToContact () {
      const element = document.querySelector('.contact')
      element.scrollIntoView({ behavior: 'smooth' })
    }
  },
  computed: {
    prolificVideoInstruction() {
      return require("@/assets/videos/prolific-video-instruction.mp4");
    },
    repeatedLearningStudyDesign() {
      return require("@/assets/images/prolific/repeated_learning_prolific_study_design.jpg");
    },
    registerWithProlificEmail() {
      return require("@/assets/images/prolific/register-with-prolific-email.png");
    },
    prolificAccessCodeAndLink() {
      return require("@/assets/images/prolific/prolific-access-code-and-link.png");
    },
    setPassword() {
      return require("@/assets/images/prolific/set-password.png");
    },
    studiesRestrictedAcess() {
      return require("@/assets/images/prolific/studies-restricted-access.png");
    },
    login() {
      return require("@/assets/images/prolific/login.png");
    },
    qrTrialsApple() {
      return require("@/assets/images/prolific/qr_ntrials_apple_app_store_medium_size.svg");
    },
    qrTrialsGoogle() {
      return require("@/assets/images/prolific/qr_ntrials_google_play_store_medium_size.svg");
    },
    appleLogo () {
      return require("@/assets/images/prolific/apple-store-logo.png");
    },
    androidLogo () {
      return require("@/assets/images/prolific/google-play-logo.png");
    },
    isWithinAllowedWeek() {
      const currentDate = new Date();
      const allowedDate = new Date();
      allowedDate.setDate(allowedDate.getDate() + 7);
      return currentDate <= allowedDate;
    }
  }
};
</script>

<style scoped>
.pred {
  color: #B00020;
}

.violet {
  color: #57509c;
}

.v-expansion-panel-text {
  max-height: 500px;
  overflow-y: auto;
}

video {
  background-color: var(--v-videoBackgroundColor-base);
  border: 1px solid var(--v-videoBackgroundColor-base);
}
</style>
